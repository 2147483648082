import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import ScanbotSDK from "scanbot-web-sdk";
import { useMyKadService } from "../services/useMyKadService";
import { useMyTenteraService } from "../services/useMyTenteraService";

const ImageProcessDialog = ({
  visible,
  id,
  docType,
  uploadFile,
  myKadSide,
  sourceOrigin,
  onSuccess,
  onError,
  onClose,
}) => {
  const [sdk, setSdk] = useState(null);

  const { perspectiveCorrection } = useMyKadService();
  const { uploadPhoto } = useMyTenteraService();

  useEffect(() => {
    if (visible) {
      const initializeSdk = async () => {
        const sdk = await ScanbotSDK.initialize({ licenseKey: "" });
        setSdk(sdk);
      };

      initializeSdk();
    }

    return () => {
      if (sdk) {
        setSdk(null);
      }
    };
  }, [visible]);

  useEffect(() => {
    if (sdk && uploadFile && visible) {
      const reader = new FileReader();

      reader.readAsArrayBuffer(uploadFile);

      reader.onload = async (e) => {
        const result = await sdk.detectDocument(reader.result, 75, 60);

        // if (result.success === true && "OK_BadAngles" === result.detectionStatus) {
        //   onError("Please take a photo from a straight angle.");
        //   return;
        // }

        if (result.success === true && "OK_SmallSize" === result.detectionStatus) {
          onError("Image is too small. Please move closer to the document.");
          return;
        }

        if (result.success === true && ["OK", "OK_BadAspectRatio"].includes(result.detectionStatus)) {
          const cropped = await sdk.cropAndRotateImageCcw(reader.result, result.polygon, 0);

          // convert uint8array to blob
          const croppedFile = new Blob([cropped], { type: "image/jpeg" });

          const formData = new FormData();
          formData.append("id", id);
          formData.append("myKadSide", myKadSide);
          formData.append("croppedImage", croppedFile);
          formData.append("originalImage", uploadFile);
          formData.append("sourceOrigin", sourceOrigin);

          let transformedBase64 = null;
          let res = docType === "MyKad" ? await perspectiveCorrection(formData) : await uploadPhoto(formData);
          if (res.status === "success") {
            transformedBase64 = res.data.transformedBase64;
          } else {
            onError(res.message ? res.message : "Unable to detect card. Please put on a flat surface and try again.");
          }
          onSuccess(transformedBase64);
        } else {
          onError("Cannot detect document. Please try again.");
        }
      };
    }
  }, [sdk, uploadFile, visible]);

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogContent>
        <Typography variant="body2">Uploading image...</Typography>
      </DialogContent>
      <LinearProgress />
    </Dialog>
  );
};

export default ImageProcessDialog;
