import { useCallback, useContext } from "react";
import { AuthContext } from "../../../App";
import { SERVER_HOST } from "../../../constants/config";

const BaseAPIUrl = SERVER_HOST;

const useEKYCService = () => {
  const { authState } = useContext(AuthContext);

  const startEkyc = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/ekyc/start", config).then((response) => response.json());
    },
    [authState]
  );

  const getValidationRecordFromToken = useCallback(
    async (token) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
      };  

      return fetch(`${BaseAPIUrl}/ekycv3?token=${token}`, config).then((response) => response.json());
    },
    [authState]
  );

  const getVerificationCertificate = useCallback(
    async (clientID, refID) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
      };

      return fetch(`${BaseAPIUrl}/verification-certificate/${clientID}/${refID}`, config).then((response) => response.json());
    },
    [authState]
  );


  return {
    startEkyc,
    getValidationRecordFromToken,
    getVerificationCertificate,
  };
};

export { useEKYCService };
