import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const MaxRetriesExceeded = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      p={3}
      textAlign="center"
    >
      <ErrorOutlineIcon sx={{ fontSize: 64, color: "#F1612E", mb: 2 }} />
      <Typography variant="h5" gutterBottom fontWeight="bold">
        Maximum Verification Attempts Exceeded
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={4}>
        You have reached the maximum number of verification attempts. 
        Please contact support for further assistance.
      </Typography>
    </Box>
  );
};

export default MaxRetriesExceeded; 