import React, { useContext } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import EKYC3 from "../modules/ekyc/EKYC3";
import CertificatePage from "../modules/ekyc/components/CertificatePage";
// import Login from "../modules/login/Login";
// import Lab from "../modules/Lab";
import { AuthContext } from "../App";
// import Dashboard from "../modules/admin/Dashboard";

// const ProtectedRoute = ({ children }) => {
//   const { authState } = useContext(AuthContext);

//   if (!authState.isAuthenticated) {
//     return <Navigate to="/admin/login" replace />;
//   }
//   return children;
// };

const ThemeRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/ekycv3" replace />,
    },
    {
      path: "/ekycv3",
      element: <EKYC3 />,
    },
    {
      path: "/verification-certificate/:clientID/:refID",
      element: <CertificatePage />,
    },
    // {
    //   path: "/lab/test-camera",
    //   element: <TestCamera />,
    // },
    // {
    //   path: "/admin/login",
    //   element: <Login />,
    // },
    // {
    //   path: "/admin/dashboard",
    //   element: (
    //     <ProtectedRoute>
    //       <Dashboard />
    //     </ProtectedRoute>
    //   ),
    // },
  ]);
};

export default ThemeRoutes;
