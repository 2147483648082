import React from 'react';
import { Button, Typography, Box, Paper } from '@mui/material';

const ExpiredOrInvalidLink = ({ errorCode }) => {

  // Determine the message based on the error code
  const errorMessage =
    errorCode === 'EXPIRED_LINK' ? 'Link Expired' : 'Invalid Link';
  const errorDescription =
    errorCode === 'EXPIRED_LINK'
      ? 'The link you are trying to access has expired.'
      : 'The link you are trying to access is invalid.';

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f8f9fa"
    >
      <Paper
        elevation={3}
        style={{
          padding: '40px',
          textAlign: 'center',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          {errorMessage}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {errorDescription}
        </Typography>
      </Paper>
    </Box>
  );
};

export default ExpiredOrInvalidLink;
