import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { EKYCContext } from "../EKYC3";
import { useEKYCService } from "../services/useEKYCService";
import packageJson from "../../../../package.json";

const schema = object().shape({
  name: string()
    // no special characters allowed. Allow alphabets, whitespace, '-', '.', '/' and apostrophe
    // .matches(/^[a-zA-Z\s]*$/, "Only alphabets and spaces are allowed")
    .matches(/^[a-zA-Z\s\-\.\'\/@]*$/, "Invalid name. No special characters allowed.")
    .required("Name is required."),
  idNumber: string().when("docType", {
    is: "MyKad" || "MyTentera",
    then: (yup) =>
      yup
        .matches(/^\d{12}$/, "Invalid ID number") // The ID number must be 12 digits long
        .matches(/^[0-9]{2}/, "Invalid ID number") // The first two digits are the birth year, so it must be between 00 and 99
        .matches(/^\d{2}(0[1-9]|1[0-2])/, "Invalid ID number") // The 3rd and 4th digits are the birth month, so it must be between 01 and 12
        .matches(/^\d{4}(0[1-9]|[12][0-9]|3[01])/, "Invalid ID number") // The next two digits are the birth day, so it must be between 01 and 31
        .matches(/^\d{6}(0[1-9]|1[0-6]|2[1-9]|[3-5][0-8]|82)/, "Invalid ID number") // The next two digits are the birth place code, so it must be between 01 to 16, 21 to 58, or 82
        .required("This field is required"),
    otherwise: (yup) =>
      yup.when("docType", {
        is: "Passport",
        then: (yup) =>
          yup.when("issueCountry", {
            is: "MYS",
            then: (yup) => yup.matches(/^[A-Z]{1}\d{8}$/, "Invalid ID number").required("This field is required"),
            otherwise: (yup) => yup.string().required("This field is required"),
          }),
      }),
  }),
  docType: string().required("This field is required"),
  issueCountry: string().when("docType", {
    is: (val) => val === "Passport",
    then: (yup) => yup.required("This field is required"),
  }),
});

const EnterData = (props) => {
  const { onNext } = props;

  const { setId, setDocType } = useContext(EKYCContext);

  const { startEkyc } = useEKYCService();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const documentType = watch("docType", "MyKad");

  useEffect(() => {
    setValue("docType", "MyKad");
  }, []);

  const onStart = async (formData) => {
    setLoading(true);

    try {
      const res = await startEkyc({
        name: formData.name,
        idNumber: formData.idNumber,
        docType: formData.docType,
        issueCountry: formData.issueCountry,
      });
      if (res.status === "success") {
        setId(res.data.sessionId);
        setDocType(formData.docType);
        onNext();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent={"center"} p={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" textAlign={"center"} my={2}>
          Welcome to the eKYC demo app
        </Typography>

        <Typography variant="body1" textAlign={"center"} my={2}>
          To begin, choose the document type and enter your details as per your identity document.
        </Typography>

        <Grid container spacing={2} mt={8}>
          {/* <Grid item xs={12}>
            <Controller
              name="docType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <FormControl fullWidth style={{ marginTop: 4 }} error={!!invalid}>
                  <InputLabel id="docType">Document Type</InputLabel>
                  <Select value={value} label="Document Type" onChange={(e) => onChange(e.target.value)}>
                    <MenuItem value={"MyKad"}>MyKad</MenuItem>
                    <MenuItem value={"MyTentera"}>MyTentera</MenuItem>
                    <MenuItem value={"Passport"}>Passport</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid> */}
          {documentType === "Passport" && (
            <Grid item xs={12}>
              <Controller
                name="issueCountry"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                  <FormControl fullWidth style={{ marginTop: 4 }} error={!!invalid}>
                    <InputLabel id="issueCountry">Country of Issue</InputLabel>
                    <Select value={value} label="Country of Issue" onChange={(e) => onChange(e.target.value)}>
                      <MenuItem value={"MYS"}>Malaysia</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  value={value}
                  onChange={(e) => {
                    // if e.target.value is special character, it will not be set to the input.
                    if (/^[a-zA-Z\s\-\.\'\/@]*$/.test(e.target.value)) {
                      onChange(e.target.value.toUpperCase());
                    }
                    // const upperCase = e.target.value.toUpperCase();
                    // onChange(upperCase);
                  }}
                  label="Full name as per identity document"
                  fullWidth
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="idNumber"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  value={value}
                  onChange={(e) => {
                    if (documentType === "MyKad" || documentType === "MyTentera") {
                      // only allow numbers
                      if (/^\d*$/.test(e.target.value)) {
                        onChange(e.target.value);
                      }
                    } else {
                      // allow numbers and alphabets
                      if (/^[A-Z0-9]*$/.test(e.target.value)) {
                        onChange(e.target.value);
                      }
                    }
                  }}
                  // label="ID/Passport number (without dashes)"
                  label="MyKad Number (without dashes)"
                  fullWidth
                  error={invalid}
                  helperText={errors.idNumber?.message}
                  type={documentType === "MyKad" || documentType === "MyTentera" ? "tel" : "text"}
                  inputProps={{ maxLength: documentType === "MyKad" || documentType === "MyTentera" ? 12 : 9 }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box textAlign={"center"} mt={4}>
          <LoadingButton loading={loading} onClick={handleSubmit((data) => onStart(data))} variant="contained">
            <span>Start</span>
          </LoadingButton>
        </Box>

        <Box textAlign={"center"} mt={4}>
          <Typography>{`v${packageJson.version}`}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EnterData;
