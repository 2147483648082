import React from 'react';
import { 
  Box, 
  Typography, 
  Container,
  Stack,
  Divider,
  Card,
  CardContent,
  alpha, 
  Link
} from '@mui/material';
import { 
  Assignment, 
  Person, 
  Numbers, 
  CalendarToday,
  Verified, 
} from '@mui/icons-material';

const VerificationCertificate = ({ 
  name, 
  idNumber, 
  refId, 
  verificationId, 
  verificationDate,
  clientName,
  clientLogo
}) => {
  return (
    <Container maxWidth="sm">
      <Card 
        elevation={4}
        sx={{ 
          position: 'relative',
          bgcolor: 'background.paper',
          mt: 4,
          overflow: 'hidden',
          borderRadius: 2,
          border: 1,
          borderColor: 'divider'
        }}
      >
        {/* Watermark Pattern */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
            opacity: 0.1,
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            p: 4,
            overflow: 'hidden',
          }}
        >
          {/* Center Shield */}
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Verified 
              sx={{ 
                fontSize: 400,
                color: 'primary.main',
                opacity: 0.5,
              }}
            />
          </Box>
        </Box>

        <CardContent sx={{ position: 'relative', zIndex: 1, p: 4 }}>
          {/* Header */}
          <Box sx={{ textAlign: 'center', py: 2 }}>
          {clientLogo === null ? (
                <span>{clientName}</span>
              ) : (
                <img src={clientLogo} alt={clientName} />
              )}
            {/* <Typography variant="h5" fontWeight="medium" gutterBottom>
              e-KYC Verification
            </Typography> */}
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              This certificate confirms that the individual named below has successfully
              completed the e-KYC verification process for {clientName}.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }}>
            <Typography variant="overline" color="text.secondary">
              Verification Details
            </Typography>
          </Divider>

          {/* Verification Details */}
          <Stack spacing={3} sx={{ px: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Person color="primary" />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Full Name
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium">
                  {name}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Assignment color="primary" />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  ID Number
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium">
                  {idNumber}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Numbers color="primary" />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Reference ID
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium">
                  {refId}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Numbers color="primary" />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Verification ID
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium">
                  {verificationId}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <CalendarToday color="primary" />
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Verification Date
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium">
                  {verificationDate}
                </Typography>
              </Box>
            </Box>
          </Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="overline" color="text.secondary">
              Certificate Information
            </Typography>
          </Divider>

          {/* Certificate Note */}
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Typography variant="body2" color="text.secondary" paragraph>
              This electronic certificate serves as official documentation of successful identity verification.
              The verification was conducted in accordance with regulatory requirements and industry standards.
            </Typography>
          </Box>

          {/* Footer */}
          <Box 
            sx={{ 
              textAlign: 'center',
              bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
              py: 2,
              px: 3,
              borderRadius: 1
            }}
          >
            <Typography 
              variant="subtitle1" 
              color="primary.main" 
              fontWeight="medium"
              gutterBottom
            >
              Verified by Kenal e-KYC
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Powered by Unbraided.ai
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 2 , display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="caption" color="text.secondary">
              Copyright &copy; {new Date().getFullYear()} Unbraided.AI. All rights reserved.
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
              Visit us at <Link href="https://www.kenal.io" target="_blank" rel="noopener noreferrer">www.kenal.io</Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default VerificationCertificate;
