import React, { useEffect, useState } from 'react';
import { useEKYCService } from '../services/useEKYCService';
import VerificationCertificate from './VerificationCertificate';
import { useParams } from 'react-router-dom';
import { SERVER_HOST } from '../../../constants/config';

const CertificatePage = () => {
    const { clientID, refID } = useParams(); // Get clientID and refID from URL parameters
    const { getVerificationCertificate } = useEKYCService();
    const [certificateData, setCertificateData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCertificateData = async () => {
            try {
                const response = await getVerificationCertificate(clientID, refID);
                if (response.status === "success") {
                    setCertificateData(response.data);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError("An error occurred while fetching the certificate data.");
            } finally {
                setLoading(false);
            }
        };

        fetchCertificateData();
    }, [clientID, refID, getVerificationCertificate]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <VerificationCertificate 
            name={certificateData.name}
            idNumber={certificateData.idNumber}
            refId={certificateData.refID}
            verificationId={certificateData.verificationID}
            verificationDate={certificateData.verificationDate}
            clientName={certificateData.clientName}
            clientLogo={certificateData.clientLogo === null ? null : `${SERVER_HOST}/storages/${certificateData.clientLogo}`}
        />
    );
};

export default CertificatePage;
